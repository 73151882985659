'use client'

import { Link } from '@/navigation'
import useMeta from '@/store/meta'
import { useBreakpoint } from '@/utils/responsive'
import Collapse from '@mui/material/Collapse'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

const FooterMenu = () => {
  const { metaInfo } = useMeta()
  const __t = useTranslations()
  const isSm = useBreakpoint('sm')
  const [activeMenus, setActiveMenus] = useState([])
  const clickMenu = (i) => (event) => {
    event.preventDefault() // Prevent the default behavior
    setActiveMenus(
      activeMenus.includes(i)
        ? activeMenus.filter((menu) => menu !== i)
        : [...activeMenus, i]
    )
  }
  const line = metaInfo?.social_channels?.find((x) => x.channel_name == 'line')
  const contactMenu = [
    {
      icon: 'fas fa-map-marker-alt',
      text: metaInfo?.address,
      link: metaInfo?.google_maps_link,
    },
    {
      icon: 'fas fa-phone-alt',
      text: metaInfo?.phone_number,
    },
    {
      link: `mailto:${metaInfo?.email}`,
      icon: 'fas fa-envelope',
      text: metaInfo?.email,
    },
    {
      link: `https://bkkhos.com/chatBHQTHMsg`,
      icon: 'fab fa-facebook-messenger',
      text: 'Facebook Messenger',
    },
    {
      link: line?.url,
      icon: `fab fa-${line?.fa_icon.icon}`,
      text: line?.text_display,
    },
  ]

  return (
    <div className="tw-bg-bgh-gray sm:tw-py-8">
      {/* Container */}
      <nav className="tw-container">
        {/* Grid */}
        <ul className="tw-grid md:tw-grid-cols-5 sm:tw-grid-cols-3 tw-grid-cols-1 sm:tw-gap-8 max-sm:tw-divide-y tw-divide-bgh-blue-alpha/20">
          {metaInfo?.main_menu?.map((menu, i) => (
            <li key={`footer-menu-${i}`}>
              {/* Main Menu */}
              <button
                onClick={clickMenu(i)}
                className={`tw-text-white/85 tw-inline-flex tw-items-center tw-space-x-2 tw-group/menu max-sm:tw-py-4 ${
                  menu.slug ? '' : 'sm:tw-pointer-events-none'
                } ${
                  menu.alwaysExpanded
                    ? ''
                    : 'max-sm:tw-w-full max-sm:tw-flex max-sm:tw-items-center max-sm:tw-justify-between'
                }`}
              >
                {/* Main icon + Always expanded icon */}
                <div className="tw-flex tw-items-center tw-space-x-2">
                  {menu.icon ? (
                    <i className={`${menu.icon} tw-text-sm`} />
                  ) : null}
                  <h6 className="tw-text-white/85 tw-duration-200">
                    {__t(menu.title_slug)}
                  </h6>
                </div>
                {/* Expanded icon */}
                {menu.alwaysExpanded ? (
                  ''
                ) : (
                  <i
                    className={`sm:!tw-hidden fas ${
                      activeMenus.includes(i) ? 'fa-minus' : 'fa-plus'
                    }`}
                  />
                )}
              </button>
              {/* Child Menu */}
              <Collapse
                in={activeMenus.includes(i) || menu.alwaysExpanded || isSm}
              >
                <ul className="tw-flex tw-flex-col tw-items-start tw-space-y-2 sm:tw-mt-4 tw-mb-4 max-sm:tw-flex">
                  {menu.items.map((item, i) => (
                    <li
                      key={`footer-sub-menu-${i}`}
                      className="tw-flex tw-items-start tw-space-x-3"
                    >
                      <Link
                        prefetch={false}
                        className="tw-text-white/85"
                        href={item.path ? item.path : '/'}
                      >
                        {__t(item.title_slug)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </li>
          ))}
          {/* Contact */}
          <div className="tw-py-4 sm:tw-py-0">
            <a
              rel="noreferrer"
              target="_blank"
              href={metaInfo?.google_maps_link}
              className="tw-block tw-mb-4"
            >
              <h6 className="tw-text-white/85 tw-space-x-2">
                <i className="fas fa-map" />
                <span>{__t('view-on-google-maps')}</span>
                <i className="fas fa-chevron-right rtl:tw-rotate-180" />
              </h6>
            </a>
            <ul className="tw-text-white/85 tw-space-y-2">
              {contactMenu.map((contact, i) => (
                <li
                  key={`contact-menu-${i}`}
                  className="[&_i]:ltr:tw-mr-2 [&_i]:rtl:tw-ml-2 [&_i]:tw-mt-1 [&_span]:tw-block tw-flex"
                >
                  {contact.link ? (
                    <a href={contact.link} className="tw-flex">
                      <i className={`${contact.icon}`} />
                      <span
                        dangerouslySetInnerHTML={{ __html: contact.text }}
                      />
                    </a>
                  ) : (
                    <>
                      <i className={`${contact.icon}`} />
                      <span
                        dangerouslySetInnerHTML={{ __html: contact.text }}
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </ul>
      </nav>
    </div>
  )
}

export default FooterMenu
