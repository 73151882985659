'use client'
import { Link } from '@/navigation'
import { useTranslations } from 'next-intl'
import { usePathname } from 'next/navigation'
import React from 'react'
import PrimaryButton from '../button/PrimaryButton'
import CCP_FooterSocial from './FooterSocial'

const FooterContact = () => {
  const pathName = usePathname()
  const __t = useTranslations()
  if (pathName.includes('contact')) return null
  return (
    <div className="bg-gradient-blue-inset tw-relative before:tw-w-full before:tw-h-full before:tw-absolute before:tw-bg-bgh-white before:-tw-z-10">
      <div className="tw-container max-sm:!tw-px-8 tw-py-12 tw-grid max-sm:tw-gap-8 sm:tw-grid-cols-2 sm:tw-divide-x sm:rtl:tw-divide-x-reverse">
        {/* Left */}
        <div className="2xl:ltr:tw-pr-12 sm:ltr:tw-pr-8 2xl:rtl:tw-pl-12 sm:rtl:tw-pl-8">
          <h4 className="tw-text-bgh-dark">{__t('stay-in-touch')}</h4>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-4">
            <Link prefetch={false} href="/newsletter" target="_blank">
              <PrimaryButton
                label={__t.raw('subscribe')}
                addedClass="tw-w-full tw-h-full sm:!tw-min-w-0"
              />
            </Link>
            <Link prefetch={false} href="/contact?go=suggestion">
              <PrimaryButton
                label={__t.raw('feedback-suggestion')}
                addedClass="tw-w-full tw-h-full sm:!tw-min-w-0"
              />
            </Link>
          </div>
        </div>
        {/* Right */}
        <CCP_FooterSocial />
      </div>
    </div>
  )
}

export default FooterContact
