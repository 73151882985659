import Image from 'next/image'

export default function SCP_CoinIcon({ level, className = '' }) {
  const icons = {
    min: 'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/icy-coin.svg',
    normal:
      'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/icy-coin-normal.svg',
    max: 'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/icy-coin-max.svg',
    modal:
      'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/coins-success-modal.svg',
    profile:
      'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/getcoin-blue.svg',
    info: 'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/coins-info.svg',
  }
  const icon = level
    ? icons[level]
    : 'https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/06/icy-coin-success.svg'

  let size = 24
  switch (level) {
    case 'min':
      size = 24
      break
    case 'normal':
      size = 24
      break
    case 'max':
      size = 48
      break
    case 'modal':
      size = 120
      break
    case 'profile':
      size = 120
      break
    case 'info':
      size = 120
      break
    default:
      size = 24
      break
  }

  return (
    <Image
      alt="coin-icon"
      width={size}
      height={size}
      src={icon}
      className={`tw-flex-none ${className}`}
    />
  )
}
