const SimpleButton = ({ label, icon, addedClass = '' }) => {
  if (!label) return
  return (
    <button
      className={`${addedClass} tw-rounded-lg md:tw-py-3 sm:tw-px-6 tw-px-4 tw-py-2 tw-bg-primary hover:tw-bg-primary/80 tw-duration-200 tw-relative tw-flex tw-items-center tw-justify-center tw-space-x-3 tw-overflow-hidden`}
    >
      {icon ? <i className={`${icon} tw-text-white`} /> : null}
      <h6
        className="tw-text-white tw-capitalize"
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </button>
  )
}

export default SimpleButton
