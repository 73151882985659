'use client'

import { Link } from '@/navigation'

export default function TouchRipple({
  children,
  href,
  className,
  isATag = false,
  prefetch = null,
  onClick = () => {},
  ariaLabel = '',
}) {
  function createRipple(event) {
    const linkButton = event.currentTarget
    const circle = document.createElement('span')
    const diameter = Math.max(linkButton.clientWidth, linkButton.clientHeight)
    const radius = diameter / 2

    circle.style.width = circle.style.height = `${diameter}px`
    circle.style.left = `${event.clientX - linkButton.offsetLeft - radius}px`
    circle.style.top = `${event.clientY - linkButton.offsetTop - radius}px`
    circle.classList.add('ripple')

    const ripple = linkButton.getElementsByClassName('ripple')[0]

    if (ripple) {
      ripple.remove()
    }

    linkButton.appendChild(circle)
    onClick(event)
  }

  if (isATag) {
    return (
      <a
        href={href}
        onClick={createRipple}
        className={`ripple-link ${className}`}
      >
        {children}
      </a>
    )
  }

  return (
    <Link
      href={href}
      onClick={createRipple}
      prefetch={prefetch}
      className={`ripple-link ${className}`}
    >
      {children}
    </Link>
  )
}
