'use client'

import useGlobal from '@/store/global'
import { Modal } from '@mui/material'

const SimpleModal = () => {
  const {
    simpleModalTitle,
    simpleModalDescription,
    simpleModalChildren,
    simpleModalWrapperClass,
  } = useGlobal()
  function handleClose() {
    useGlobal.setState({
      simpleModalTitle: null,
      simpleModalDescription: null,
      simpleModalChildren: null,
      simpleModalWrapperClass: '',
    })
  }

  return (
    <Modal
      open={simpleModalTitle || simpleModalChildren}
      onClose={handleClose}
      aria-labelledby="center-modal"
      disableAutoFocus={true}
      closeAfterTransition
    >
      <>
        {/* Close Icon */}
        <button
          onClick={() => handleClose()}
          className="tw-absolute tw-right-0 tw-top-0 tw-z-10 tw-flex tw-size-12 tw-items-center tw-justify-center tw-rounded-bl-xl tw-bg-bgh-gray-primary hover:tw-bg-bgh-gray-primary-hover tw-duration-200"
        >
          <i
            aria-hidden="true"
            className="far fa-times tw-text-center tw-text-xl tw-text-white tw-duration-100 "
          />
        </button>
        <div
          className={`mui-modal tw-max-h-[80vh] ${simpleModalWrapperClass} ${simpleModalChildren ? 'sm:!tw-max-w-3xl' : 'sm:!tw-max-w-xl'}`}
        >
          {simpleModalTitle ? <h4>{simpleModalTitle}</h4> : null}
          {simpleModalDescription ? (
            <p className="tw-mt-2">{simpleModalDescription}</p>
          ) : null}
          <>{simpleModalChildren}</>
        </div>
      </>
    </Modal>
  )
}

export default SimpleModal
