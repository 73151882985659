import getNumberWithCommas from '@/utils/getNumberWithCommas'
import SCP_CoinIcon from './CoinIcon'
import useGlobal from '@/store/global'
import SCP_CoinInfo from './CoinInfo'

export default function SCP_CoinDisplay({
  points,
  level = 'min',
  type = 'a',
  addedClass = '',
  showInfo = false,
}) {
  // Points
  if (!points || points === 0) return null
  let _points = ''
  switch (type) {
    case 'a':
      _points = points > 0 ? `+ ${points}` : `${points}`
      break
    case 'b':
      _points = getNumberWithCommas(points)
      break
    case 'c':
      _points = getNumberWithCommas(points)
      break
    case 'd':
      _points = getNumberWithCommas(points)
      break
  }
  // Info
  function openInfoModal() {
    useGlobal.setState({
      simpleModalChildren: <SCP_CoinInfo />,
    })
  }

  return (
    <div
      onClick={() => openInfoModal()}
      className={`${addedClass} 
      ${showInfo ? 'tw-cursor-pointer' : 'tw-pointer-events-none'} 
      ${type === 'c' ? '!tw-border' : ''} 
      ${points < 0 ? 'tw-grayscale' : ''} 
      ${type === 'b' || type === 'c' || type === 'd' ? 'ltr:tw-flex-row-reverse ltr:tw-space-x-reverse ltr:tw-justify-end' : 'tw-p-1  tw-bg-bgh-fpd-blue-light'} 
      ${type === 'd' ? 'tw-h-min tw-bg-white tw-shadow-fpd-blue' : ''}
      ${type === 'c' || type === 'd' ? '!tw-pr-2 tw-p-1' : ''}
      tw-rounded-full  tw-flex tw-items-center tw-space-x-1`}
    >
      <h6
        className={`tw-text-sm !tw-leading-snug ${type === 'b' || type === 'c' ? '' : 'tw-text-bgh-fpd-blue-dark'}`}
      >
        {_points}
      </h6>
      <SCP_CoinIcon level={level} />
    </div>
  )
}
