export default function SCP_ArrowButton({
  label,
  addedClass = '',
  isReversed = false,
  icon = 'fa-chevron-right',
}) {
  if (!label) return null
  return (
    <div
      className={`tw-inline-flex tw-items-center tw-group tw-cursor-pointer ${addedClass} ${
        isReversed ? 'tw-flex-row-reverse' : 'tw-flex-row'
      }`}
    >
      <h6 className="tw-uppercase md:tw-text-base tw-text-sm">{label}</h6>
      <i
        className={`fal ${icon} md:tw-text-base tw-text-sm tw-text-primary tw-duration-200 rtl:tw-rotate-180 ${
          isReversed
            ? 'ltr:tw-mr-2 rtl:tw-ml-2  group-hover:ltr:-tw-translate-x-1 group-hover:rtl:tw-translate-x-1'
            : 'ltr:tw-ml-2 rtl:tw-mr-2  group-hover:ltr:tw-translate-x-1 group-hover:rtl:-tw-translate-x-1'
        }`}
      />
    </div>
  )
}
