'use client'

import useGlobal from '@/store/global'
import useMeta from '@/store/meta'
import submitSearchParams from '@/utils/submitSearchParams'
import { Modal, Button } from '@mui/material'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import { usePathname, useRouter } from '@/navigation'
import { useState } from 'react'
import SCP_ArrowButton from '../button/ArrowButton'
import CenterCard from '../card/CenterCard'
import SCP_IconCardSmall from '../card/IconCardSmall'

import getPostImage from '@/utils/post/getPostImage'
import getPostTitle from '@/utils/post/getPostTitle'
import useAppointment from '@/store/appointment'

const SelectCenterModal = () => {
  const __t = useTranslations()
  const searchParams = useSearchParams()
  const { push } = useRouter()
  const pathname = usePathname()
  const [centerGroups, centers] = useMeta((s) => [s.centerGroups, s.centers])
  const { isShowSelectCenterModal, centerModalType } = useGlobal()
  const [selectedCenters, setSelectedCenters] = useState([])
  const { setForm } = useAppointment()
  const shouldShowShortTitle = centerModalType == 'specialty'

  const selectDoctorFromAppointment =
    pathname?.includes('/appointment') && centerModalType === 'doctor'

  if (!isShowSelectCenterModal) return null

  function handleClose() {
    setSelectedCenters([])
    useGlobal.setState({
      isShowSelectCenterModal: false,
      centerModalType: '',
    })
  }

  function selectCenterGroup(centerGroup) {
    if (centerGroup === 'all' || !centerGroup || !centerGroup._slug) {
      let destinationPathname = pathname

      //Select doctor From Appointment page
      if (selectDoctorFromAppointment) {
        destinationPathname = '/doctor?from=appointment'
      }

      push(destinationPathname)
      handleClose()
      useGlobal.setState({
        isShowDoctorFilter: false,
      })
      return
    }

    let selectCenterIds = centerGroup.centers
    let selectedCenters = selectCenterIds
      .map((id) => centers.find((center) => center._id === id))
      .filter((x) => x?.title)
      .filter((x) => x.department_code || x.doctor_slug)
    setSelectedCenters(selectedCenters)
  }

  function selectCenter(center) {
    let destinationPathname = pathname

    if (selectDoctorFromAppointment) {
      destinationPathname = '/doctor'
    }

    if (centerModalType === 'specialty') {
      setForm({
        doctor_specialty: center?.title,
        doctor_name: '',
        doctor_slug: '',
      })
      push(pathname)
      handleClose()
      return
    }

    submitSearchParams(searchParams, push, destinationPathname, {
      centerSlug: center._slug,
      from: selectDoctorFromAppointment ? 'appointment' : null,
    })

    handleClose()
    useGlobal.setState({
      isShowDoctorFilter: false,
    })
  }

  return (
    <Modal
      open={isShowSelectCenterModal}
      onClose={handleClose}
      aria-labelledby="center-modal"
      disableAutoFocus={true}
      closeAfterTransition
    >
      <>
        {/* Close Icon */}
        <button
          onClick={() => handleClose()}
          className="tw-absolute tw-right-0 tw-top-0 tw-z-10 tw-flex tw-size-12 tw-items-center tw-justify-center tw-rounded-bl-xl tw-bg-bgh-gray-primary hover:tw-bg-bgh-gray-primary-hover tw-duration-200"
        >
          <i
            aria-hidden="true"
            className="far fa-times tw-text-center tw-text-xl tw-text-white tw-duration-100 "
          />
        </button>
        <div className="mui-modal !tw-py-6">
          <div className="tw-flex tw-flex-col tw-items-center tw-mb-5">
            {centerModalType === 'doctor' ? (
              <>
                <h2>{__t('find-doctors')}</h2>
              </>
            ) : (
              <>
                {centerModalType === 'specialty' ? (
                  <h2 className="tw-text-2xl">
                    {__t('please-select-doctor-specialty')}
                  </h2>
                ) : (
                  <>
                    <p className="tw-text-center">{__t('please-select')}</p>
                    <h2>{__t('centers-clinics')}</h2>
                  </>
                )}
              </>
            )}
          </div>
          {/* Centers */}
          {!selectedCenters.length ? (
            <div className="tw-grid sm:tw-grid-cols-4 tw-grid-cols-2 sm:tw-gap-4 tw-gap-2 tw-pb-1">
              {centerGroups && centerGroups.length
                ? centerGroups.map((centerGroup, i) => (
                    <div
                      key={`center-group-filter-${i}`}
                      onClick={() => selectCenterGroup(centerGroup)}
                    >
                      <SCP_IconCardSmall
                        iconSrc={getPostImage(centerGroup, '', 'icon')}
                        title={getPostTitle(centerGroup, shouldShowShortTitle)}
                      />
                    </div>
                  ))
                : null}
              {/* All Doctors */}
              {centerModalType === 'doctor' && (
                <div onClick={() => selectCenterGroup('all')}>
                  <SCP_IconCardSmall
                    icon="far fa-list"
                    title={__t('all-doctors')}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div onClick={() => setSelectedCenters([])}>
                <SCP_ArrowButton
                  isReversed
                  label={__t('back')}
                  icon="fa-chevron-left"
                />
              </div>
              <div className="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-6 tw-mt-4">
                {selectedCenters.map((center, i) => (
                  <div onClick={() => selectCenter(center)} key={`center-${i}`}>
                    <CenterCard
                      isLoading={false}
                      post={center}
                      isShortTitle={shouldShowShortTitle}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {centerModalType === 'specialty' && (
            <div className="tw-flex tw-justify-center sm:tw-justify-end tw-mt-8">
              <div
                className="tw-underline tw-text-bgh-gray-primary tw-decoration-bgh-gray-primary/10 tw-underline-offset-4 tw-font-bold tw-decoration-4 tw-cursor-pointer tw-text-sm"
                onClick={() => {
                  setForm({
                    doctor_specialty_selection_method: 'not-sure',
                    doctor_name: '',
                    doctor_slug: '',
                    doctor_info: {
                      specialty: '',
                    },
                  })
                  handleClose()
                }}
              >
                {__t('im-not-sure')}
              </div>
            </div>
          )}
        </div>
      </>
    </Modal>
  )
}

export default SelectCenterModal
